.calculator-button-container{
    position: fixed;
    right: 0px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    background-color:#0db02b ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 10;
    font-size: larger;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 1px;
    }

    .calculator-icon{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      
    }
    
.calculator-button-container:hover{
    right: 50px;
    bottom: -55px;
        height: 180px;
       
}

.calculator-button-container-header{
    display: none;
}
.calculator-button-container:hover .calculator-button-container-header{
display: block;
-webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  
}
.calculator-button-container:hover .calculator-icon{
    margin-bottom: 20px;
       
}