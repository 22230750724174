.home-footer-container{
    margin-top: 8%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-rows: minmax(270px,auto);
    grid-gap: 20px;
    border: none;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    padding: 2%;
   
 }
.home-footer-container1{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 1px 2px 5px 6px #e6e6e6;
    border-radius: 15px;
    
   
}

.home-footer-container2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    opacity: 1;
    border: none;
    box-shadow: 1px 2px 5px 6px #e6e6e6;
    border-radius: 15px;
      
}

.home-footer-container2 div{
    padding-top: 5%;
    margin-left: 3%;
    margin-right: 3%;
   
}
.home-footer-container2-header{
   padding-bottom: 10px;
    text-align: justify;
    font-family: Lato, sans-serif;
    font-size: 35px;
    line-height: 27px;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
   

}
.home-footer-container2 a{
    text-align: justify;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
}



.testimoniyal-container{
display: flex;
flex-direction: row;
align-items: center;
justify-content:center;
width: 100%;
}

.testimoniyal-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    height: 200px;
    align-self: center;
    
}

.testimoniyal-content-header{
    padding-bottom: 10px;
    text-align: justify;
    font-family: Lato, sans-serif;
    font-size: 35px;
    line-height: 27px;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
   

}

.testimoniyal-content p {
    text-align: center;
    margin-bottom: 5%;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 40px;
    font-weight: 400;
    
}

.testimoniyal-content-name {
    text-align: center;
    font-size: 20px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    
}

.back-button1{
    display: none;
font-size: 30px;
cursor: pointer;
}
.next-button1{
    display: none;
    font-size: 30px;
    cursor: pointer;
    
}

