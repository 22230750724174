.bottom-main-container{
    margin-top: 1%;
    padding: 3%;
 
    display: flex;
    flex-direction: column;
    padding-top: 4%;
    background-color: #fff;
box-shadow: 
inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);

}
.bottom-logo-container{
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.footer-image-container{
    width: 120px;
    height: 90px;
 
}
.bottom-logo{
    width: 100%;
    height: 100%;
}
.bottom-container{
    margin-top: 2%;
    height: 100px;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
background: #ffffff 0% 0% no-repeat padding-box;

}
.bottom-logo-container div{
    text-align: justify;
    font-family: Lato, sans-serif;
    font-size: 20px;
    line-height: 27px;

}
.border1{
    width: 100%;
    height:2px;
    background-color: #eeeeee ;
}

.header{
width: 20%;
height: 50px;
box-shadow: 1px 2px 5px 3px #00000029;
border-radius: 15px;
background: #ffffff 0% 0% no-repeat padding-box;
display: flex;
align-items: center;
justify-content: center;
}

.arm-solar-solutions{
    font-family: Lato, sans-serif;
   
    font-size: 20px;
   font-weight: bold;
    text-decoration: none;
    color: #343434;

}

.bottom-container-contact-us-button{
    font-family: Lato, sans-serif;
   
    font-size: 20px;
   font-weight: bold;
    text-decoration: none;
    color: #343434;
}

@media screen and (max-width: 850px){
    .bottom-container{
        width: 95%;
    
    }
    
}

@media screen and (max-width: 600px){
    .bottom-container{
        width: 90%;
    
    }

    .arm-solar-solutions{
        width: 40%;
        font-family: Lato, sans-serif;
       
        font-size: 15px;
       font-weight: bold;
        text-decoration: none;
        color: #343434;
    
    }
    
    .bottom-container-contact-us-button{
        font-family: Lato, sans-serif;
       
        font-size: 15px;
       font-weight: bold;
        text-decoration: none;
        color: #343434;
    }
    
    
}
