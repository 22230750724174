.product-main-container{
    position: relative;
display: flex;
flex-direction: column;
}

.filter-container{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 25px;
    font-family: Lato, sans-serif;
    color: #333;
  }

.product-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-rows: minmax(400px,auto);
    grid-gap: 20px;
    opacity: 1;
    margin-top: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
   
  }
.gallery{
    position: relative;
    display: flex;
    flex-direction: row;
   }

.product-brand-logo-and-description{
  padding: 5%;

   height: 94%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    
    
}

.product-brand-logo-and-description p{
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  

}
.product-logo-container{
    
  }

.product-description-container{
    width: 100%;
    border: none;
    border-radius: 5px;
    opacity: 1;
   display: flex;
   align-items: center;
   justify-content: center;
 }

.product-image{
width: 100%;
height: 100%;
border-radius: 15px;
}


.enquiry-button-container{
    width: 120px;
    height: 40px;
    border: 1px solid  #66bb6a;
    border-radius: 5px;
    opacity: 1;
   
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:  #0db02b;
    color: white;
}

.product-container2{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-rows: minmax(400px,auto);
    grid-gap: 20px;
    border: none;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 3%;
    margin-bottom: 3%;
    padding-left:2% ;
    padding-top: 2%;
    padding-bottom: 2%;
    
  }
.gallery2{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
   }

.product-brand-logo-and-description2{
    padding: 2%;
   height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
 }

 .product-brand-logo-and-description2-for-solar-battery{
    padding: 2%;
   height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
 }

.product-description-container2{
    width: 93%;
    border: none;
    border-radius: 5px;
    opacity: 1;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
}
.product-description-container2 p{
    text-align: justify;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
}

.product-image2{
width: 100%;
height: 65%;
border-radius: 15px;
}

.enquiry-button-container2{
    width: 150px;
    height: 45px;
    border: 1px solid  #0db02b;
    border-radius: 5px;
    opacity: 1;
   cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    background-color: #0db02b ;
    letter-spacing: 1px;
    font-weight: bold;
    text-decoration: none;

}

.enquiry-button-container2:hover{
    background-color: #3e8e41
}


.input-box-container1{
    width: 80%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
}

.input-text1{
    width: 70%;
    height: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    background-color: #fafafa ;
    border-width: 1px;

}

.product-container-header{
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 40px;
    line-height: 60px;
    font-weight: 900;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
   
}

@media screen and (max-width: 650px){
    .product-container-header{
        font-size: 28px;
        line-height: 60px;
        font-weight: 900;
       
    }
    
}