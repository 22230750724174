.contact-button-container{
position: fixed;
right: 0px;
bottom: 65px;
width: 50px;
height: 50px;
background-color:#0db02b ;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: white;
z-index: 10;
font-size: larger;
font-weight: 500;
text-decoration: none;
letter-spacing: 1px;
-webkit-transform: rotate(-90deg);
-moz-transform: rotate(-90deg);
-o-transform: rotate(-90deg);
-ms-transform: rotate(-90deg);
transform: rotate(-90deg);

}
.contact-icon{
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    transform: rotate(130deg);
  
}

 .contact-button-container:hover {
    
    position: fixed;
right: 50px;
bottom: 15px;
height: 150px;
    
}
.contact-button-container-header{
    display: none;
}
.contact-button-container:hover .contact-button-container-header{
display: block;
-webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  
}
