/*fix header container */
.container{
    z-index: 10;
    position: sticky;
    top:0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 40px;
    align-self: center;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
    
}
/*header of contact */
 .header-of-contact{
    margin-left: 50px;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #333;
 }
 /*icon container */
.icon-container{
    position: absolute;
    right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}

@media screen and (max-width: 650px){
    .container{
        display: none;
    }

    .header-of-contact{
        margin-left: 20px;
     }
  
    .icon-container{
        position: absolute;
        right: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
    }
   
}
@media screen and (max-width: 500px){
    .container{
        height: 50px;
        background-color: aqua;
       }
    
     .header-of-contact{
        margin-left: 5px;
     }
    .icon-container{
        position: absolute;
        right: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
    }
    

}
@media screen and (max-width: 400px){
    .container{
        height: 40px;
       }
    
     .header-of-contact{
        margin-left: 20px;
     }
    .icon-container{
        position: absolute;
        right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
    }
    

}
.border{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height:1px;
    background-color: #eeeeee;
}
@media screen and (max-width: 350px){
    .container{
        height: 40px;
       }
    
     .header-of-contact{
        margin-left: 10px;
     }
    .icon-container{
        right: 10px;
        
    }
    

}