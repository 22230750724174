.menu1{
    display: none
  }
  .siderbar-logo-container{
      display: none;
  }

  .menu-container{
display: none;   
}

.main-container{
    position: relative;
    display: flex;
    flex-direction: column;
  }


.image-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-columns: minmax(300px,auto);
    grid-gap: 20px;
    padding-bottom: 2%;
    padding-left:2%;
    padding-right: 2%;
    padding-top: 20px;

}

.image-or-slider-container{
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
   
 }

.home-contact-us-container1{
  width: 90%;
height: 600px; 
  
 }

 .contact-form-container{
     display: flex;
     width: 100%;
     flex-direction: column;
     align-items: flex-start;
     justify-content:space-evenly;
     height: 100%;
    }

.contact-us-form{
    width:100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  border: none;
  
   }

   
.home-contact-us-form-header {
    margin-top: 2%;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 40px;
    font-weight: 400;
}
.contact-form-container-paragraph{

    width: 90%;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 40px;
    line-height: 40px;
    font-weight: 400;
    font-weight: bold;
}
.rolling-solar-news{
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    border: none;
border-radius: 5px;
opacity: 1;

}

.partners-logo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 550px;
    border: none;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 10px;
   margin-top: 5%;
   padding:2%
    
    
}

.partners-logo strong{
    margin-left:5%;
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 50px;
    line-height: 60px;
    font-weight: 900;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
   
}

.partners-logo-underliner{
    height: 2px;
    width: 25%;
    background-color:#9e9e9e ;
}

.partners-logo p{
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 10px;

}

.partners-logos-container{
    
    align-self: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}


.rolling-number-for-quick-fact-container{
    margin-top: 5%;
    padding: 1%;
    display: flex;
    flex-direction: column;
   
    height: 260px;
    border: none;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
   
}
.why-ARM-container{
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-columns: minmax(300px,auto);
    grid-gap: 20px;
  
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    padding-left:2% ;
    padding-top: 4%;
  
}
.why-ARM-card-header{
    margin-left: 5%;
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 50px;
    line-height: 60px;
    font-weight: 900;
    text-align: left;
    margin-bottom: 20px;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
   
 
 
}
.why-ARM-subcontainer{
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.why-ARM-card-container{
    margin-top: 5%;
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.why-ARM-card{
    padding: 4%;
    width: 22%;
    height: 100%;
    border-radius: 10px;
background-color: #fff;
box-shadow: 
inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-evenly;

}
.why-ARM-card-icon{
font-size: 50px;
}

.why-ARM-card-subcontainer-header{
    width: 100%;
   
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
    text-align: left;
    
}

.why-ARM-card p{
    width: 100%;
    text-align: justify;
    font-family: Lato, sans-serif;
    font-size: 18px;
    line-height: 27px;
}
.gallery{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.sub-galley{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-columns: minmax(300px,auto);
    grid-gap: 20px;
  
    height: 100%;
}
.gallery-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-images{
    width: 55%;
    height: 50%;

}

.gallery-images2{
    width:180;
    height:100
}

.sliderimg{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 15px;
   
  
}

.input-box-container{
    width: 95%;
    height: 50px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
border-radius: 10px;

}

.input-text-for-name{
    padding:2%;
    width: 45%;
    height: 40%;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
   font-size: 20px;
letter-spacing: 1px;
}

.input-text-for-pincode{
    padding:2%;
    width: 45%;
    height: 40%;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
   font-size: 20px;
letter-spacing: 1px;

}
.input-text-for-email{
    padding:2%;
    width: 45%;
    height: 40%;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
 font-size: 20px;
 
letter-spacing: 1px;

}

.input-text-for-service{
    width: 49%;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
font-size: 21.5px;

}

.input-text-for-mobile{
    padding:2%;
    width: 45%;
    height: 40%;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
   font-size: 20px;
letter-spacing: 1px;

}

.input-text-for-projecttype{
    margin-top: 7px;
    width: 49%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
 font-size: 22px;

}


.description-text{
    margin-top: 5%;
    padding: 2%;
    width: 100%;
border-radius: 10px;
    height: 100%;
    border-color: #eeeeee;
    border-width: 1px;
    font-size: 20px;
    background-color: #ffffff;
    letter-spacing: 1px;
    font-family: Montserrat, sans-serif;
    
}
  
.submit-button{
    margin-top: 5%;
    cursor: pointer;
    width: 105px;
    height: 50px;
    background-color: #0db02b ;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
   font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    letter-spacing: 1px;
    font-weight: bold;

}

.submit-button:hover{
    background-color: #3e8e41
}

.contact-form-header-container{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 10px;
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
 
  }

  .contact-form-header{
      padding-left: 5%;
      color: #343434;
      font-weight: bold;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
   
  }

.content-block {
    margin-top: 2%;
    background: white 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px;
    width: 96%;
    height: 190px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
   padding: 2%;
   
}

.rolling-number-for-quick-fact-container .title strong {
   margin-left: 5%;

   font-family: Lato, sans-serif;
   color: #333;
   font-size: 50px;
   line-height: 60px;
   font-weight: 900;
   text-align: left;
   text-decoration: underline;
   text-decoration-color: #9edfaa;
   text-decoration-thickness: 6px;
  
   

}
.quick-fact-container{
    padding: 2%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
 
    width: 18%;

   }
.content-block .number {
    font-size: 25px;
    color: #333;
    font-family: Lato, sans-serif;
    font-weight: bold;

   }

.content-block .sub-title {
    font-size: 20px;
    color:#343434;
    font-family: Lato, sans-serif;
  
    margin-top: 20px
}

.image-of-panters-logo{
 width: 80%;
height: 50%;
object-fit: center;
object-position: center;

}

.image-of-organisation-logo{
    width: 92%;
   height: 92%;
   object-fit: center;
   object-position: center;
   
   }

.image-of-panters-logo-of-tier{
    width: 90%;
   height: 90%;
   object-fit:contain;
   object-position: center;
   
   }
   .image-of-panters-logo-of-rahe{
    width: 90%;
   height: 90%;
   object-fit:contain;
   object-position: center;
   
   }

.panter-logo-subcontainer{
    width: 25%;
    height: 200px;
    display: flex;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
    align-items: center;
    justify-content: center;
    object-fit: contain;

}

.image-of-panters-logo-middle{
    width: 70%;
   height: 50%;
   object-fit: fill;
   object-position: center;
   }

.rolling-solar-news-subcontainer{
    max-height: 80px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
    
   }
.rolling-solar-news a{
    text-decoration: none;
    text-align: center;
    margin-bottom: 5%;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 40px;
    font-weight: 400;
    
}
.active {
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 3px;
       
}



@media screen and (max-width: 650px){
    .menu1 {
        padding-top: 5%;
        padding-left: 3%;
        cursor: pointer;
        margin-right: 20px;
     display: unset;
     left: 15px;
     font-size: 40px;
     color: #828282
   } 
    
    .siderbar{
        position: absolute;
        top:0px;
        width: 100%;
        height: 300px;
        background-color: #DCDCDC;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 10;
    }
    .menu-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
     }
    .menu-header{
        padding-left: 2%;
        padding-top: 4%;
        font-size: 25px;
        font-weight: bold;
    }
    .menu-header1{
        font-size: 22px;
        font-weight: bold;
    }
    
    .siderbar-logo-container{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .siderbar-cancel-button{
        cursor: pointer;
       width: 50px;
       height: 50px;
       background-color: black; 
       border-radius: 25px;
       color: white;
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 40px;
       font-weight: bold;
    }
    
    .siderbar-item{
        width: 80%;
        border: 1px solid #707070;
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 2%;
        border-radius: 10px;
        background-color: #e5e5e5;
    }

    .siderbar-item1{
        color:black;
        font-size: 20px;
        text-decoration: none;
    }
    
}


@media screen and (max-width: 1000px){

    .contact-form-container-paragraph{

        font-size: 35px;
        line-height: 40px;
        font-weight: 350;
        font-weight: bold;
    }

    .contact-us-form{
        height: 100%;
        display: flex;
      
       }
    
       .input-box-container{
        height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    
    }
    
    .input-text-for-name{
        margin-top: 2%;
       
        width: 85%;
        height: 30%;
    }
    
    .input-text-for-pincode{
        margin-top: 2%;
       
        width: 85%;
        height: 30%;
       
    }
    .input-text-for-email{
        margin-top: 2%;
       
        width: 85%;
        height: 30%;
     
    }
    
    .input-text-for-service{
        margin-top: 2%;
       
        width: 90%;
        background-color: #ffffff;
        border-radius: 10px;
        border-color: #eeeeee;
        border-width: 1px;
    font-size: 21.5px;
    
    }
    
    .input-text-for-mobile{
        margin-top: 2%;
       
        width: 85%;
        height: 30%;
    
    }
    
    .input-text-for-projecttype{
        margin-top: 2%;
       
        width: 90%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        border-color: #eeeeee;
        border-width: 1px;
     font-size: 22px;
    
    }
    
    
    .description-text{
        margin-top: 2%;
        padding: 2%;
        width: 85%;
    border-radius: 10px;
        height: 100%;
        border-color: #eeeeee;
        border-width: 1px;
        font-size: 20px;
        background-color: #ffffff;
        letter-spacing: 1px;
        font-family: Montserrat, sans-serif;
        
    }
      
    
    
    .image-of-panters-logo-middle{
        width: 75%;
       height: 40%;
       }

       .image-of-panters-logo{
        width: 75%;
       height: 40%;
       
       }

       .quick-fact-container{
     
        width: 20%;
        height: 150px;
    
       }
    

       .content-block .sub-title {
        font-size: 20px;
        color:#343434;
        font-family: Lato, sans-serif;
      
        margin-top: 10px
    }

    .why-ARM-card-container{
        margin-top: 5%;
        height: 75%;
      }
    
      .image-of-organisation-logo{
        width: 190px;
       height: 190px;
       
       }

       .image-of-panters-logo-of-tier{
        width: 150px;
       height: 150px;
       object-fit:contain;
       object-position: center;
       
       }
       .image-of-panters-logo-of-rahe{
        width: 150px;
        height: 150px;
       
        object-fit:contain;
       object-position: center;
       
       }
    
    
    
    
      
    
       
    
}



@media screen and (max-width: 800px){
    .image-of-panters-logo-middle{
        width: 120px;
       height: 60px;
       }

       .image-of-panters-logo{
        width: 120px;
        height: 60px;
       
       }
       
       .image-of-organisation-logo{
        width: 150px;
       height: 150px;
       
       }

       .image-of-panters-logo-of-tier{
        width: 120px;
       height: 120px;
       object-fit:contain;
       object-position: center;
       
       }
       .image-of-panters-logo-of-rahe{
        width: 120px;
        height: 120px;
        
        object-fit:contain;
       object-position: center;
       
       }
    
  
}

@media screen and (max-width: 650px){
    .image-of-panters-logo-middle{
        width: 100px;
       height: 40px;
       }

       .image-of-panters-logo{
        width: 100px;
        height: 40px;
       
       }

       
       .why-ARM-card-container{
        margin-top: 5%;
        height: 80%;
      }
    

      .image-of-organisation-logo{
        width: 100px;
       height: 100px;
       
       }

       .image-of-panters-logo-of-tier{
        width: 80px;
       height: 80px;
       object-fit:contain;
       object-position: center;
       
       }
       .image-of-panters-logo-of-rahe{
        width: 80px;
        height: 80px;
        
        object-fit:contain;
       object-position: center;
       
       }
    
    
  
}

@media screen and (max-width: 500px){
    .image-of-panters-logo-middle{
        width: 60px;
       height: 20px;
       }

       .image-of-panters-logo{
        width: 60px;
        height: 20px;
       
       }

       .content-block{
        padding: 0%;
        width: 100%;
       }

       .quick-fact-container{
        
        width: 20%;
        height: 140px;
    
       }
    

       .content-block .sub-title {
        font-size: 15px;
        color:#343434;
        font-family: Lato, sans-serif;
      
        margin-top: 10px
    }
  
    .why-ARM-card-container{
        margin-top: 5%;
        height: 90%;
      }

      .why-ARM-card-icon{
        font-size: 35px;
        }
        
        .why-ARM-card-subcontainer-header{
            width: 100%;
           
            font-family: Lato, sans-serif;
            color: #333;
            font-size: 15px;
            line-height: 25px;
            font-weight: 900;
            text-align: left;
            
        }
        
        .why-ARM-card p{
            width: 100%;
            text-align: justify;
            font-family: Lato, sans-serif;
            font-size: 16px;
            line-height: 27px;
        }
        
        .image-of-organisation-logo{
            width: 80px;
           height: 80px;
           
           }
    
           .image-of-panters-logo-of-tier{
            width: 60px;
           height: 60px;
           object-fit:contain;
           object-position: center;
           
           }
           .image-of-panters-logo-of-rahe{
            width: 60px;
            height: 60px;
            
            object-fit:contain;
           object-position: center;
           
           }
        
    
}