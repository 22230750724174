.main-container{
    position: relative;
    display: flex;
    flex-direction: column;
}

.contact-us-container{
display: grid;

}

.image-container2{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-rows: minmax(300px,auto);
    grid-gap: 20px;
}

.image-or-slider-container1{
    width: 100%;
    height: 450px;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
border-radius: 5px;
opacity: 1;

}

.contact-us-form-header1{
    margin-left:4%;
    font-family: Lato, sans-serif;
  
    color: #333;
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;

}
.contact-us-form-header2{
    margin-top: 8%;
    font-family: Lato, sans-serif;
  
    color: #333;
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
   
}

.contact-us-form2{
    width:100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  border: none;
  
   }


.contact-us-container1{
    padding-top: 8%;
    height: 500px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
}

.contact-us-container2{
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start; 
}

.contact-us-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: none;
border-radius: 5px;
opacity: 1;



   }

.rolling-solar-news1{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
border-radius: 15px;
opacity: 1;

}


.map-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   
 }
 .map-container div{
    padding: 2%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
 
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

 }

 .map-container2 div{
     
     margin-left: 3%;
     margin-right: 3%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
 padding: 2%;
    width: 42%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
 }
 .map-container-content{
     width: 100%;
     font-size: 12px;
     text-align: center;
}
 .map-container strong{
       font-size: 40px;
    font-family: Lato, sans-serif;
  
 }
 .map-container p{
     text-align: center;
   width: 100%;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  
 }

 .map-container2{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   
 }
 .map-container2 strong{
       font-size: 40px;
    font-family: Lato, sans-serif;
  
 }
 .map-container2 p{
    text-align: center;
    width: 100%;
  
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  
 }



 .image-container3{
    margin-top: 5%;
    
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-rows: minmax(300px,auto);
    grid-gap: 20px;
    
}

.product-logo-container div{
    font-size: 35px;
    font-family: Lato, sans-serif;
   font-weight: 600;
}
.product-description-container p{
    
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 35px;
    font-weight: 400;
   
}

.contact-submit-button-container{
    display: flex;
    align-items: center;
    justify-content: center;

}

.submit-button1{
    margin-top: 10%;
    cursor: pointer;
    width: 105px;
    height: 50px;
    background-color: #0db02b ;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
   font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    letter-spacing: 1px;
    font-weight: bold;

}

.submit-button1:hover{
    background-color: #3e8e41
}

@media screen and (max-width: 500px) {
 
.contact-us-form-header2{
    margin-top: 8%;
    margin-left: 2%;
    font-size: 20px;
    line-height: 35px;
    width: 80%;
  }
}
