/*header main container*/
.header-main-container{
    z-index: 10;
    background-color:white;
   
    position: sticky;
    top:40px;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-top: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
   

   }
/*logo container*/
.logo-container{
    width: 120px;
    height: 90px;
    margin-left: 30px;
    background-position: center;
    background-size: cover;

}


/*nav-item container*/
.nav-item-container{
    position: absolute;
    right: 30px;
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.nav-item-container-item{
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
 font-weight: bold;
    float: none;
    text-decoration: none;
    display: block;
    text-align: left;
    color: #333;
    letter-spacing: 2px;
    padding-bottom: 5px;
}


@media screen and (max-width: 850px){
    
    .logo-container{
        margin-left: 25px;
      
    }
    
    .nav-item-container{
        position: absolute;
        right: 30px;
        width: 500px;
      } 
}

@media screen and (max-width: 700px){
    
    .logo-container{
        margin-left: 20px;
      
    }
    
    .nav-item-container{
        position: absolute;
        right: 30px;
        width: 450px;
      } 
}

@media screen and (max-width: 650px){
    .header-main-container{
        display: none;
    }
    
    .logo-container{
        margin-left: 15px;
      
    }
    
    .nav-item-container{
        position: absolute;
        right: 25px;
        width: 300px;
      } 
}

@media screen and (max-width: 500px){
    
    .logo-container{
        margin-left: 15px;
      
    }
    
    .nav-item-container{
        position: absolute;
        right: 20spx;
        width: 280px;
      } 
}

@media screen and (max-width: 400px){
    
    .logo-container{
        margin-left: 10px;
      
    }
    
    .nav-item-container{
        position: absolute;
        right: 10px;
        width: 265px;
      } 
}