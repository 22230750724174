.calculator-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-columns: minmax(300px,auto);
    grid-gap: 20px;
    border: none;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.images-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-auto-columns: minmax(300px,auto);
    grid-gap: 20px;
    border-radius: 5px;
    opacity: 1;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    
  
}

.output-container{
    padding: 2%;
    width: 77%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 2px 5px 6px #e6e6e6;
    border-radius: 5px;
    justify-self: center;
  
}

.output-of-electricity-bill{
    margin-top: 20px;
    font-size: 20px;
    
}

.output-container-bottom-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

}

.image1{
    display: flex;
    border: 1px solid #616161;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    
}

.calculator-form{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    display: block;
    position: relative;
    padding: 24px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 1px 2px 5px 6px #e6e6e6;
    border-radius: 5px;
    
}

.calculator-form-header{
    display: flex;
    align-self: center;
    justify-content: center;
}

.calculator-form-header strong{
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 40px;
    line-height: 60px;
    font-weight: 900;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
   
}
.calculator-form h1{
    text-align: center;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 24px;
    line-height: 32px;
    font-family:  sans-serif;
}

.calculator-form-pincode-container{
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.location-icon{
    font-size: 30px;
    color: aqua;
    margin-right: 15px;
}

.current-pincode-container{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.current-pincode-container2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

}

.current-pincode-input-container{
 width: 200px;
 height: 30px;
 border: 1px solid #F0F0F0;
 border-radius: 10px;
 padding: 5px;
 margin-right: 20px;

}

.button-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
justify-content: space-around;

}


.change-button{
    cursor: pointer;
    width: 88px;
    height:36px;
    background-color: #08439D;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}


.electricity-bill-button{
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
    height: 55px;
    background-color:   #0db02b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 15px;
    text-align: center;
}

.electricity-bill-button:hover{
    background-color: #3e8e41
}

.another-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.electricity-bill-container{
    margin-top: 2%;
    width: 93%;
    height: 100px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 
    inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
  

}



.electricity-bill-input-container{
    width: 100%;
    height: 30px;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    padding: 5px;
    margin-right: 20px;
    text-align: justify;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 27px;

    
   }
   

   .calculate-btn{
    cursor: pointer;
    width: 40%;
    height: 48px;
    background-color:  #0db02b;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    font-family: Montserrat, sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    border-radius: 5px;

}

.calculate-btn:hover{
    background-color: #3e8e41
}

.two-appliances-container{
    margin-top: 1%;
    width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly; 
background-color: #fff;
box-shadow: 
inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
height: 80px;
}

.appliances-container{
    margin-top: 10px;
    padding: 2%;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
   
}
.table-fan-input-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.calculator-page-button-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.calculate-btn2{
    margin-top: 30px;
    align-self: center;
    cursor: pointer;
    width: 40%;
    height: 48px;
    background-color:  #0db02b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    text-align: justify;
    font-family: Montserrat, sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    border-radius: 5px;

}

.calculate-btn2:hover{
    background-color: #3e8e41
}

.input-text-for-name1{
    padding:3%;
    width: 75%;
    height: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #eeeeee;
    border-width: 1px;
text-align: justify;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 16px;
    line-height: 27px;
    
}

.contact-container2-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
     text-align: justify;
     font-family: Lato, sans-serif;
     font-size: 18px;
     line-height: 15px;
    
 
 }
 .dropdown-arrow{
     font-size: 20px;
     cursor: pointer;
 }

 .option-box-header{
    width:80px;
    text-align: justify;
    font-family: Lato, sans-serif;
    font-size: 25px;
    line-height: 27px;
    font-weight: 600;
  
 }

 .option-box-container{
     display: flex;
     align-items: center;
     justify-content: center;
     width: 96%;
     height: 50px;
     background-color: #fff;
     box-shadow: 
     inset 1px 1px 20px 5px rgba(0, 0, 0, 0.05);
   
 }

 .output-container strong{
    font-family: Lato, sans-serif;
    color: #333;
    font-size: 40px;
    line-height: 60px;
    font-weight: 900;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #9edfaa;
    text-decoration-thickness: 6px;
    
 }

 @media screen and (max-width: 1000px){
    .output-container{
        width: 79%;
    }
    
}


 @media screen and (max-width: 800px) {
    .calculator-form{
        width: 90%;
    
        
    }

    .calculate-btn{
        width: 60%;
        font-size: 15px;
       
    }

    .calculate-btn2{
        width: 60%;
        font-size: 15px;
       
    }
    .electricity-bill-button{
        font-size: 13px;

    }
    
    .output-container{
        width: 90%;
    }
   
    

 }


 @media screen and (max-width: 550px) {
    .calculator-form{
        width: 91%;
    
        
    }

    .calculate-btn{
        width: 60%;
        font-size: 12px;
       
    }

    .calculate-btn2{
        width: 60%;
        font-size: 12px;
      
    }

    .electricity-bill-button{
        font-size: 11px;
        height: 40px;
    }
    
    
    .output-container{
        width: 92%;
    }

    .header-for-another-container{
        font-size: 11px;
     
    }

   
    

 }